import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ForgotPasswordAction} from '../../redux/actions/AuthActions';
import {useNavigate,Link} from 'react-router-dom';
import { useForm } from "react-hook-form";

function ForgotPasswordForm() {

    const { register, handleSubmit, formState: { errors } } = useForm(); // for form control
    const history = useNavigate();
    const dispatch = useDispatch();
    const authResponse = useSelector(state=>state.userAuth.authResponse);

    // handle submit
    const onSubmit = (data) =>{
        var fields = { email: data.email, password: data.password };
        dispatch(ForgotPasswordAction(fields, history));
    }




    return (
        <>
            <h2>Forgot Password</h2>

            <form id="forgot-password-form">

                <div className="row">
                    <div className={`half-width ${errors["email"] ? "has-error" : ''}`}>
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" placeholder="enter email" {...register('email', {required: true, minLength: 3, maxLength: 35})}/>
                    </div>
                </div>

                {/* show submission erros if they exist */}
                { authResponse.success === false ? ( <div className="row"> <p>{authResponse.message}</p> </div> ) : null}

                <div className="form-bottom-button-wrapper">
                    <button type="submit" className="button button-dark" onClick={handleSubmit(onSubmit)}>Submit</button>
                </div>

            </form>

            <div className="row">
                <Link to="/login">Return to login</Link>
            </div>
        </>

    ) // [end] return function

} //[end] ForgotPassword

export default ForgotPasswordForm
