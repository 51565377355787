import React from 'react';
import DeleteIcon from '../assets/images/icon-x-indigo.png';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        <div className='modal-close' onClick={onClose}><img src={DeleteIcon} className='delete-icon' alt="close"/></div>
      </div>
    </div>
  );
}

export default Modal;
