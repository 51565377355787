// import general layout components
import LeftPanel from '../Layouts/LeftPanel';
import RightPanel from '../Layouts/RightPanel';
import CompanyLogo from '../CompanyLogo';
import LanguageSelector from '../LanguageSelector';
import CardCentered from '../CardCentered';

import React from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {RegisterAction} from '../../redux/actions/AuthActions';
import { useForm } from "react-hook-form";
import {useNavigate} from 'react-router-dom';



function RegisterComponent() {

    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm(); // for form control
    const dispatch = useDispatch();
    const authResponse = useSelector(state=>state.userAuth.authResponse);
    const nav = useNavigate();


    // handle submit
    const onSubmit = (data) =>{
        // check and make sure passwords match
        if(data.password === data.password_confirm){

            var fields = {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
                password: data.password,
                password_confirmation: data.password_confirm
            };

          dispatch(RegisterAction(fields));

          if(authResponse.success){
              alert('Registration Successful');
              nav("/login");
          }

        } //[end] check if passwords match
    }// [end] on submit







    return (
        <>
        <div className="bg-topo-dark">
                <LeftPanel/>
                <RightPanel className="bg-white">
                    <CompanyLogo className="white-panel-company-logo" />
                    <LanguageSelector className="language-selector text-navy" />
                    <CardCentered>
                        <h2>New User Registration</h2>
                        <form>
                            <div className="row">
                                <div className={`half-width ${errors["first_name"] ? "has-error" : ''}`}>
                                    <label htmlFor="first_name">First *</label>
                                    <input type="text" name="first_name" placeholder="enter first name" {...register('first_name', {required: true, minLength: 3, maxLength: 25})}/>
                                </div>
                                <div className={`half-width ${errors["last_name"] ? "has-error" : ''}`}>
                                    <label htmlFor="last_name">Last *</label>
                                    <input type="text" name="last_name" placeholder="enter last name" {...register('last_name', {required: true, minLength: 3, maxLength: 25})}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className={`half-width`}>
                                    <label htmlFor="email">Email *</label>
                                    <input type="email" name="email" placeholder="enter email" {...register('email', {required: true, minLength: 3, maxLength: 35})}/>
                                </div>

                                <div className={`half-width`}>
                                    <label htmlFor="phone">Phone *</label>
                                    <input type="phone" name="phone" placeholder="enter phone number" {...register('phone', {required: true, minLength: 10, maxLength: 18})}/>
                                </div>
                            </div>

                            <div className="row">
                                <div className={`half-width ${errors["password"] ? "has-error" : ''}`}>
                                    <label htmlFor="password">Password *</label>
                                    <input type="password" name="password" placeholder="enter password" {...register('password', {required: true, minLength: 3, maxLength: 35})}/>
                                </div>
                                <div className={`half-width ${errors["password_confirm"] ? "has-error" : ''}`}>
                                    <label htmlFor="password_confirm">Confirm Password *</label>
                                    <input type="password" name="password_confirm" placeholder="confirm password" {...register('password_confirm', {required: true, minLength: 3, maxLength: 35})}/>
                                </div>
                            </div>


                            {/* show submission erros if they exist */}
                            { !authResponse.success ? ( <div className="row"> <p>{authResponse.message}</p> </div> ) : null}
                            {watch("password_confirm") !== watch("password") && getValues("password_confirm") ? ( <div className="row"> <p>passwords do not match</p> </div> ) : null}


                            <div className="form-bottom-button-wrapper">
                                <button type="submit" className="button button-dark" onClick={handleSubmit(onSubmit)}>Register</button>
                            </div>

                        </form>

                        <div className="row">
                            <Link to="/login">Login Here</Link>
                        </div>

                    </CardCentered>
                </RightPanel>
            </div>

</>
  ); //[end] return function
}

export default RegisterComponent;
