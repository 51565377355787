import CountdownTimer from '../CountdownTimer';
import { useSelector } from 'react-redux';

const TrainingFinished = ({ children, className }) => {
  const language = useSelector((state) => state.language.active_language);
  const location = useSelector((state) => state.location.name);
//   const location = 'Tervuren Headquarters';
  return (
    <>
      <div className="training-finished-wrapper">
        <div className="text-wrapper">
          <h2>{language.your_done_text}</h2>
          <p>
            {language.your_done_subtext} {location}.
          </p>
          <p>{language.your_done_subtext_coutdown}</p>
        </div>
        <div className="countdown-timer-wrapper">
          <CountdownTimer />
        </div>
      </div>
    </>
  );
};
export default TrainingFinished;
