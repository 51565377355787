import stackedLogo from "../../assets/images/tervuren-logo-white-vert-lg.png"


export default function VideoWelcomeOverlay(props){
    return(
        <div className="video-panel-overlay">
            <div className="inner-wrapper">
                <img className="terv-logo" src={stackedLogo} alt="Stacked Logo"/>
                <div className="button-wrapper">
                    {/* TODO: sub this out for the right language */}
                    <button className="button button-blue" onClick={props.startVideo}>Start Video</button> 
                </div>
            </div>
        </div>
    )
}