/*
    In this class, we have four functions and a global variable which is our url.
    Remember our Laravel API was set up on localhost:8000/api (/api because Laravel automatically append it to our APIs)
*/

export default class HttpService {
  // url of the api. this needs to be changed when moving spaces!
  APP_ENV =
    process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT !== '/prod' ? '/test' : '';

  url = 'https://7rx419w9sf.execute-api.us-east-2.amazonaws.com' + this.APP_ENV;
  //url = "https://api.tervuren.app/api";

  /*
        postData takes three parameters: items, added_url and token_ID which is set to an empty string by default.
        Since we will be using our local storage to store our tokens, we will first check if the token exists in our local storage.
    */

  patchRequestOptions = (token, item) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const requestOptions = {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(item),
    };

    return requestOptions;
  };
  postData = async (item, added_url, tokenId = '') => {
    //const token = await localStorage.getItem(tokenId);
    const token = await localStorage.getItem('user-token');

    // takes the item (body of data sent from the API) and token.
    const requestOptions = this.postRequestOptions(token, item);

    // access the API using the Fetch API, which takes in the url, which is a concatenated string containing our base url (the global variable) and the added url and the requestOptions as the second argument
    return fetch(this.url + '/' + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  putData = async (item, added_url, tokenId = '') => {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.postRequestOptions(token, item);

    // Update the request options with the PUT method
    requestOptions.method = 'PUT';

    return fetch(this.url + '/' + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  patchData = async (item, added_url, tokenId = '') => {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.patchRequestOptions(token, item);

    // Update the request options with the PATCH method
    requestOptions.method = 'PATCH';

    return fetch(this.url + '/' + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  /*
        getData function is very similar to the postData. The only difference is that it takes in 2 parameters (added_url and tokenID)
    */
  getData = async (added_url, tokenId = '') => {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.getRequestOptions(token);

    return fetch(this.url + '/' + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  /*
        getRequestOptions takes token as the argument. We create a requestOptions object variable which contains the http method used, the headers (authorization and content-type). We then return the object.
    */
  getRequestOptions = (token) => {
    let requestOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
    };
    return requestOptions;
  };

  /*
        PostRequestOptions is similar to getRequstOptions. However, it takes in two parameters (token and item). The method is POST and it also has a body.
    */
  postRequestOptions = (token, item) => {
    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };
} //[end] HttpService class
