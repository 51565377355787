import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GetPossibleTraineesAction } from '../../redux/actions/TraineeActions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseJwt } from '../../redux/actions/AuthActions';
import LoadingButton from '../LoadingButton';

export default function WhosTraining() {
  const language = useSelector((state) => state.language.active_language);
  const user = useSelector((state) => state.user);
  console.log(user);
  //   const training_time = useSelector((state) => state.location.training_time);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [isEmpty, setIsEmpty] = useState(true); // track if the canvas is empty or not
  useEffect(() => {
    // [start] log out user if the token has expired
    const decodedJwt = parseJwt(localStorage.getItem('user-token'));

    if (decodedJwt) {
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem('user-token');
        alert('Youre session has expired, please login again');
        // need to add a pop up
        // need to add a cleaning out measure for the state
        nav('/login');
      }
    }
    // [end] log out user if the token has expired

    // next comment line -> old es lint versions flag this but it is okay. this is just hiding a warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    // if it made it here, the form already validated through react hook form validation

    // set up trainee object to send to api to look up possible trainees
    let trainee_object = {};
    if (data.first_name !== '' && data.last_name !== '') {
      trainee_object.first_name = data.first_name;
      trainee_object.last_name = data.last_name;
      trainee_object.location_id = user.location_id;
    }
    if (data.phone !== '') {
      trainee_object.phone = data.phone;
    }
    if (data.company !== '') {
      trainee_object.company = data.company;
    }
    dispatch(GetPossibleTraineesAction(trainee_object, user));
  }; // [end] submit function
  const onChange = (e) => {
    if (e.target.value.length >= 7) {
      setIsEmpty(e.target.value.trim() === '');
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <>
      <div className="whos-training-wrapper">
        <h2 className="training-heading">
          {language.whos_training_welcome_text}
        </h2>
        <p className="training-text">
          {language.whos_training_welcome_subtext}
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
          <div className={`half-width ${errors['phone'] ? 'has-error' : ''}`}>
            <label htmlFor="phone">{language.form_phone} *</label>
            <input
              type="phone"
              name="phone"
              onInput={onChange}
              placeholder={`${language.form_phone_placeholder}`}
              {...register('phone', {
                required: 'Phone number is required',
                minLength: {
                  value: 10,
                  message: 'Phone must be at least 10 characters',
                },
                maxLength: {
                  value: 18,
                  message: 'Phone must be no more than 18 characters',
                },
              })}
            />
            {errors.phone && (
              <p className="error-message">{errors.phone.message}</p>
            )}
          </div>
          <div className={`half-width ${errors['company'] ? 'has-error' : ''}`}>
            <label htmlFor="company">{language.form_company}</label>
            <input
              type="text"
              placeholder={`${language.form_company_placeholder}`}
              {...register('company', {
                required: false,
                minLength: {
                  value: 3,
                  message: 'Company name must be at least 3 characters',
                },
                maxLength: {
                  value: 65,
                  message: 'Company name must be no more than 65 characters',
                },
              })}
            />
            {errors.company && (
              <p className="error-message">{errors.company.message}</p>
            )}
          </div>
        </div>
        
        <div className="row">
          <div
            className={`half-width ${errors['first_name'] ? 'has-error' : ''}`}
          >
            <label htmlFor="first_name">{language.form_first_name}</label>
            <input
              type="text"
              placeholder={`${language.form_first_name_placeholder}`}
              {...register('first_name', {
                required: false,
                minLength: {
                  value: 3,
                  message: 'First name must be at least 3 characters',
                },
                maxLength: {
                  value: 65,
                  message: 'First name must be no more than 65 characters',
                },
              })}
            />
            {errors.first_name && (
              <p className="error-message">{errors.first_name.message}</p>
            )}
          </div>
          <div
            className={`half-width ${errors['last_name'] ? 'has-error' : ''}`}
          >
            <label htmlFor="last_name">{language.form_last_name}</label>
            <input
              type="text"
              placeholder={`${language.form_last_name_placeholder}`}
              {...register('last_name', {
                required: false,
                minLength: {
                  value: 3,
                  message: 'Last name must be at least 3 characters',
                },
                maxLength: {
                  value: 65,
                  message: 'Last name must be no more than 65 characters',
                },
              })}
            />
            {errors.last_name && (
              <p className="error-message">{errors.last_name.message}</p>
            )}
          </div>
        </div>

       

        <div className="form-whos-training-bottom-button-wrapper">
          <LoadingButton
            initialLabel={language.add_trainee_button || 'ADD TRAINEE'}
            onSubmit={handleSubmit(onSubmit)}
            type="submit"
            loadingLabel="Finding Trainee..."
            extraClasses={isEmpty ? 'inactive' : ''}
          />
        </div>
      </form>
    </>
  ); //[end return]
} //[end] who's training function
