import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPasswordAction } from '../../redux/actions/AuthActions';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom'; // Import useLocation

function ResetPasswordForm() {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);

  // Parse the query parameters to extract email and token
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token'); // Extract token
  const email = searchParams.get('email'); // Extract email

  // Handle form submission
  const onSubmit = (data) => {
    if (
      data.password === data.password_confirm &&
      token !== '' &&
      email !== ''
    ) {
      const fields = {
        email: email.replace(/'/g, ''), // Remove single quotes if present
        token: token.replace(/'/g, ''), // Remove single quotes if present
        password: data.password,
      };

      dispatch(ResetPasswordAction(fields));
    }
  };

  return (
    <>
      <form>
        <div className="row">
          <div
            className={`half-width ${errors['password'] ? 'has-error' : ''}`}
          >
            <label htmlFor="password">New Password *</label>
            <input
              type="password"
              name="password"
              placeholder="enter password"
              {...register('password', {
                required: true,
                minLength: 3,
                maxLength: 35,
              })}
            />
          </div>
          <div
            className={`half-width ${
              errors['password_confirm'] ? 'has-error' : ''
            }`}
          >
            <label htmlFor="password_confirm">Confirm Password *</label>
            <input
              type="password"
              name="password_confirm"
              placeholder="confirm password"
              {...register('password_confirm', {
                required: true,
                minLength: 3,
                maxLength: 35,
              })}
            />
          </div>
        </div>

        {/* show submission errors if they exist */}
        {!authResponse.success ? (
          <div className="row">
            {' '}
            <p>{authResponse.message}</p>{' '}
          </div>
        ) : null}
        {watch('password_confirm') !== watch('password') &&
        getValues('password_confirm') ? (
          <div className="row">
            {' '}
            <p>passwords do not match</p>{' '}
          </div>
        ) : null}

        <div className="form-bottom-button-wrapper">
          <button
            type="submit"
            className="button button-dark"
            onClick={handleSubmit(onSubmit)}
          >
            Save New Password
          </button>
        </div>
      </form>
    </>
  ); //[end] return function
} // [end] ResetPassword()
export default ResetPasswordForm;
