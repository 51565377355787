import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active_trainee: {},
  number_of_trainees: 0,
  completed_trainee_list: [],
  trainee_list: [],
  possible_trainee_list: [],
  connectionResponse: '',
  all_trainees_list: [], //for testing
}; // end initial state object

/**
 * Create a slice as a reducer containing actions.
 */
export const traineeSlice = createSlice({
  name: 'trainees',
  initialState,
  reducers: {
    addTrainee: (state, action) => {
      state.trainee_list.push(action.payload);
      state.number_of_trainees++;
    },

    addCompletedTrainee: (state, action) => {
      // should be getting a full trainee object
      let trainee = action.payload;
      // add trainee to finished list
      state.completed_trainee_list.push(trainee);
    },

    removeTrainee: (state, action) => {
      // remove from trainee list

      let trainee_id = action.payload;

      //remove trainee from the trainee list
      try {
        state.trainee_list = state.trainee_list.filter(
          (obj) => obj.id !== trainee_id
        );
        // drop number of employees
        state.number_of_trainees--;
      } catch (err) {
        state.number_of_trainees = 0;
        state.trainee_list = [];
        console.log(err);
      }
    },

    setActiveTrainee: (state, action) => {
      state.active_trainee = action.payload;
    },

    removeActiveTrainee: (state, action) => {
      //reset active trainee object
      state.active_trainee = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company: '',
        visitor_type: '',
        msha_number: '',
        truck_number: '',
        last_training: '',
        last_training_agreement: '',
        last_training_video: '',
        signature: '',
        preferred_language: '',
      };
    },

    setActiveTraineeByID: (state, action) => {
      var found_trainee = state.trainee_list.filter((obj) => {
        return obj.id === action.payload;
      });
      state.active_trainee = found_trainee[0];
    },
    // for testing. maybe needed in future.
    loadAllTrainees: (state, action) => {
      state.all_trainees_list = action.payload;
    },

    setTraineeConnectionResponse: (state, action) => {
      state.connectionResponse = action.payload;
    },

    setPossibleTrainees: (state, action) => {
      state.possible_trainee_list = action.payload;
    },

    clearPossibleTrainees: (state, action) => {
      state.possible_trainee_list = [];
    },
    resetTraineesState: () => initialState, // Reset to initial state
  }, // [end] reducers
}); // [end] slice function

// Export all actions, Action creators are generated for each case reducer function
export const {
  addTrainee,
  removeTrainee,
  addCompletedTrainee,
  setActiveTrainee,
  removeActiveTrainee,
  setActiveTraineeByID,
  loadAllTrainees,
  setTraineeConnectionResponse,
  setPossibleTrainees,
  clearPossibleTrainees,
  resetTraineesState,
} = traineeSlice.actions;

export default traineeSlice.reducer;
