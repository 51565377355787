
const RightPanel = ({ children, className}) => {
    return (

        <div className={`right-panel ${className}`}>
            {children}
        </div>

    )
}

export default RightPanel
