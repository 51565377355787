import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setScreen, setBoth } from '../redux/reducers/PageReducer';
import shield from '../assets/images/Blue-Shield-Icon.png';


function CountdownTimer() {
  const dispatch = useDispatch();
  const trainees_list = useSelector((state) => state.trainees.trainee_list);

  const [counter, setCounter] = useState(5);

  // when the page loads, start the counter. when it hits 0, switch the page depeneding on if there are any trainees left to go
  useEffect(() => {

    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      if (trainees_list.length > 0) {
        dispatch(setScreen('start'));
      } else {
        // if none left, go back to the welcome screen
        dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
      }
    }
    // next comment line -> old es lint versions flag this but it is okay. this is just hiding a warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  return (
    <>
      <div className="spinner">
        <div className="spinner-line">
          <div className="spinner-line-cog">
            <div className="spinner-line-cog-inner spinner-line-cog-inner-left"></div>
          </div>
          <div className="spinner-line-ticker">
            <div className="spinner-line-cog-inner spinner-line-cog-inner-center"></div>
          </div>
          <div className="spinner-line-cog">
            <div className="spinner-line-cog-inner spinner-line-cog-inner-right"></div>
          </div>
        </div>
      </div>
      <div className="countdown">
        <h2 id="countdown-timer">{counter}</h2>
        <img src={shield} alt="Countdown Timer"></img>
      </div>
    </>
  );
}
export default CountdownTimer;
