
import { Menu } from '@headlessui/react'

const DropdownLink = ({ children, ...props }) => (
    <Menu.Item>{({ active }) => (

            <a className={` ${active ? 'bg-gray-100' : ''}`}>
                {children}
            </a>

    )}
    </Menu.Item>
)

export const DropdownButton = ({ children, ...props }) => (
    <Menu.Item>{({ active }) => (
        <a
            className={`w-full text-left block px-4 py-2 text-sm leading-5 text-gray-700 ${active ? 'bg-gray-100' : ''} focus:outline-none transition duration-150 ease-in-out`}
            {...props}>
            {children}
        </a>
    )}
    </Menu.Item>
)

export default DropdownLink
