import TraineeInfoForm from './TraineeInfoForm';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';

// if has info, fill in the form and show site training expiration information
// show header text based on if the user exists or not

// Set the output format for every react-moment instance.
Moment.globalFormat = 'MM/DD/YYYY';

function ReviewTraineeInfo() {
  const language = useSelector(
    (state) => state.language.active_language || state.language
  );
  // need to get information on whether this trainee is new or already has info
  const active_trainee = useSelector((state) => state.trainees.active_trainee);
  let last_training = active_trainee.last_training; // come in format 2022-08-11 22:15:03
  var returning_trainee = false;
  var days_till_expires = 364;

  if ('id' in active_trainee) {
    if (active_trainee.id > -1) {
      returning_trainee = true;
    }
  }

  //[start] check for leap years:
  if (last_training) {
    last_training = last_training.toString();
    var year = parseInt(last_training.substring(0, 4));
    var month = parseInt(last_training.substring(5, 7));
    var day = parseInt(last_training.substring(8, 10));
    // get year. if it is divisible by 4 and before feb 29, add 365 days
    if (year % 4 === 0 && month < 3) {
      days_till_expires = 365;
      if (month === 2 && day > 28) {
        days_till_expires = 364;
      }
    }
    // if the next year is divisible by 4 and the date is after feb 28, add 365 days
    if ((year + 1) % 4 === 0 && month > 2) {
      days_till_expires = 365;
    }
  } //[end] check for leap years

  // return view based on if the trainee exists.
  if (returning_trainee) {
    return (
      <>
        <div className="whos-training-wrapper">
          <div>
            <h2 className="training-heading">
              {language.form_welcome_back_text}
            </h2>
            <p className="training-text">
              {language.form_welcome_back_subtext}
            </p>
          </div>

          {/* Swap what is shown based on if the training is expired or not */}
          {active_trainee.training_expired || last_training == null ? (
            <div id="site-training-expiration-wrapper">
              <p>{language.form_training_expired_text}</p>
            </div>
          ) : (
            <div id="site-training-expiration-wrapper">
              <p>{language.form_training_will_expire_text}</p>
              <p className="bold">
                {' '}
                <Moment
                  date={last_training}
                  add={{ days: days_till_expires }}
                  format="MM/DD/YYYY"
                />{' '}
              </p>
            </div>
          )}
        </div>
        <TraineeInfoForm />
      </>
    );
  } else {
    return (
      <>
        <div className="whos-training-wrapper">
          <div>
            <h2 className="training-heading">{language.form_new_here_text}</h2>
            <p className="training-text">{language.form_new_here_subtext}</p>
          </div>
        </div>
        <TraineeInfoForm />
      </>
    );
  } //[end else]
} //[end] reviewtraineeInfo()

export default ReviewTraineeInfo;
