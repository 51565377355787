import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setScreen } from '../../redux/reducers/PageReducer'


function TrainingAgreement(){

    const language = useSelector((state) => state.language.active_language);
    const active_trainee = useSelector((state) => state.trainees.active_trainee);
    const agreementData = useSelector((state) => state.location.agreement);
    const last_name = active_trainee.last_name;
    const first_initial = active_trainee.first_name.substring(0,1);
    const [isValid, setIsValid] = useState(false);
    const dispatch = useDispatch();
    const listInnerRef = useRef(); // connect scrolling container
    const [hasScrolled, setHasScrolled] = useState(false);
    const [agreeIsChecked, setAgreeIsChecked] = useState(false);
    const [TrainingAgreement, setTrainingAgreement] = useState([]);
    const [isForm, setIsForm] = useState(false);
    const [FormCheckboxStates, setFormCheckboxStates] = useState({});
    const [formIsAllChecked, setFormIsAllChecked] = useState(false);


    useEffect(() => {
        const loadAgreement = () => {
            try {                
                if (agreementData) {
                    setTrainingAgreement(agreementData.items);
                    setIsForm(agreementData.isForm === 'true');
                } else {
                    // Handle the case when agreementData is not available
                }
            } catch (error) {
                console.error('Error processing data:', error);
                // Handle the error
            }
        };
    
        loadAgreement();
    
        // Check for overflow after a slight delay to ensure the DOM has updated
        setTimeout(() => {
            checkForOverflow();
        }, 100);
    }, [agreementData]);
    

    // this function will check if the content is overflowing and set scroll to bottom if not
    const checkForOverflow = () => {
        if (listInnerRef.current) {
            const { scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollHeight <= clientHeight) {
                // No overflow, content doesn't need scrolling
                setHasScrolled(true);
            }
        }
    };

    // this function will check if the user has scrolled to the bottom of the agreement
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                setHasScrolled(true);
            }
        }
    };
    

    // Initialize checkbox states for the form if it is one
    useEffect(() => {
        // Initialize checkbox states
        const initialStates = {};
        TrainingAgreement.forEach((_, index) => {
            initialStates[`agreement-item-${index}`] = false;
        });
        setFormCheckboxStates(initialStates);
    }, [TrainingAgreement]);


    // Update checkbox states when the form is changed
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const updatedStates = { ...FormCheckboxStates, [name]: checked };
        setFormCheckboxStates(updatedStates);
    
        // Check if all checkboxes are checked
        const allChecked = Object.values(updatedStates).every(state => state);
        setFormIsAllChecked(allChecked);
    };
    
    
    

    // check if the overall agreement checkbox is checked
    const agreementCheckboxClicked = (checkbox) =>{
        setAgreeIsChecked(checkbox.target.checked);
    }
    
    // check if the user can continue
    useEffect(() => {
        if (isForm) {
            // When it's a form, all checkboxes need to be checked along with agreeIsChecked and hasScrolled
            setIsValid(agreeIsChecked && hasScrolled && formIsAllChecked);
        } else {
            // When it's not a form, just check agreeIsChecked and hasScrolled
            setIsValid(agreeIsChecked && hasScrolled);
        }
    }, [agreeIsChecked, hasScrolled, formIsAllChecked, isForm]); // Include formIsAllChecked and isForm in the dependency array
    
    

    // go to signature screen
    const goToSignature = () => {
        dispatch(setScreen('signature'));
    }

    useEffect(() => {
       // console.log(`agreeIsChecked: ${agreeIsChecked}, hasScrolled: ${hasScrolled}, formIsAllChecked: ${formIsAllChecked}`);
        if (isForm) {
            setIsValid(agreeIsChecked && hasScrolled && formIsAllChecked);
        } else {
            setIsValid(agreeIsChecked && hasScrolled);
        }
    }, [agreeIsChecked, hasScrolled, formIsAllChecked, isForm]);
    

    useEffect(() => {
        const initialStates = {};
        TrainingAgreement.forEach((item, index) => {
            if (item.type === 'checkbox') {
                initialStates[`agreement-item-${index}`] = false;
            }
        });
        setFormCheckboxStates(initialStates);
    }, [TrainingAgreement]);
    


    return (
        <>
            <div className="signature-trainee-info">
                <p className="trainee-short-name">{first_initial}. {last_name}</p>
            </div>

            <div id="agreement-box" className="scroll-box-wrapper">
                <div id="scroll-box-body" onScroll={onScroll} ref={listInnerRef}>
                    {TrainingAgreement.map((item, index) => (
                        isForm ? (
                            <>
                                {item.type === 'checkbox' && (
                                    <div className="checkbox-item">
                                        <input
                                            type="checkbox"
                                            id={`agreement-item-${index}`}
                                            name={`agreement-item-${index}`}
                                            checked={FormCheckboxStates[`agreement-item-${index}`] || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor={`agreement-item-${index}`}>
                                            {/* <span className="agreement-form-title">{item.title}</span> */}
                                            <p className="agreement-form-body">{item.body}</p>
                                        </label>
                                    </div>
                                )}
                                {item.type === 'bold' && (
                                    <div className="bold-item">
                                        <p className="text-bold">{item.title}</p>
                                        <p>{item.body}</p>
                                    </div>
                                )}
                                {/* {item.type === 'list' && (
                                    <div className="list-item">
                                        <p className="text-underline">{item.title}</p>
                                        <ul>
                                            {item.items.map((listItem, listIndex) => (
                                                <li key={`${index}-${listIndex}`}>{listItem}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )} */}
                                {item.type === 'text' && (
                                    <div className="text-item">
                                        <p className="text-underline">{item.title}</p>
                                        <p>{item.body}</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <React.Fragment key={index}>
                                <p className="text-underline">{item.title}</p>
                                <p>{item.body}</p>
                                <br />
                            </React.Fragment>
                        )
                    ))}
                </div>
            </div>
            <div className="agreement-checkbox-wrapper">
                <form>
                    <input type="checkbox" id="agree-checkbox" name="agreed" onChange={(e) => {agreementCheckboxClicked(e)}} required />
                    <label htmlFor="agreed">{language.agreement_checkbox_text}</label>
                </form>
            </div>
            <div className="review-card-footer">
            {isValid ? (
                <button className="button button-blue" onClick={goToSignature}>{language.continue_button}</button>
            ) : (
                <button className="button button-blue inactive">{language.continue_button}</button>
            )}
            </div>
        </>
    )// [end] return function



}// [end] main function

export default TrainingAgreement
