/*
    this file will enable us to navigate between different pages of our application.
*/

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Pages/Main';
import Login from './components/Pages/Login';
import Logout from './components/Pages/Logout';
import Register from './components/Pages/Register';
import { Guard } from './Guards';
import ForgotPassword from './components/Pages/ForgotPassword';
import ResetPassword from './components/Pages/ResetPassword';
// import TestPage from './components/Pages/TestPage';

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        {/* <Route path="/test" element={<TestPage/>}/> */}

        {/* routes protected by user token */}
        <Route element={<Guard token="user-token" routeRedirect="/login" />}>
          <Route path="/" element={<Navigate to="/training" replace />} />
          <Route path="/training" element={<Home />} />
          <Route path="/*" element={<Navigate to="/training" replace />} />
        </Route>

        {/* keep this last to catch everything. it will check if logged in first and that will redirect to traing instead of login */}
        <Route path="/*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
}
export default AppRoutes;
