// import image from '../assets/images/Tervuren-Yellow-Logo-500.png';
import image from '../assets/images/tervuren-logo-white-vert-lg.png';

const ApplicationLogo = props => (
    <>
        <img src={image} alt="Main Logo" {...props}/>
    </>
)

export default ApplicationLogo
