import React from 'react';
import Pagination from './Pagination';
import TraineeListItem from './TraineeListItem';
import { useSelector, useDispatch } from 'react-redux';
import { setScreen, setBoth } from '../redux/reducers/PageReducer';
import { setActiveTraineeByID } from '../redux/reducers/TraineesReducer';
import { SetCommonLanguageAction } from '../redux/actions/LanguageActions';
import { removeActiveTrainee } from '../redux/reducers/TraineesReducer';

export default function TraineeList() {
  const dispatch = useDispatch();
  const current_display = useSelector((state) => state.page.display);
  const trainees_list = useSelector((state) => state.trainees.trainee_list);
  const active_trainee = useSelector((state) => state.trainees.active_trainee);
  //useSelector((state) => console.log(state));
  const goBack = () => {
    // clear active trainee
    dispatch(removeActiveTrainee());
    // move back to training lobby
    dispatch(setScreen('training-lobby'));
  };

  // if no trainees, go to welcome screen - catch all
  if (trainees_list.length < 1) {
    dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
  }

  // move ahead if start training button clicked.
  // There are two instances of this list in the app, to begin training and to go into the agreement area
  const startTraining = () => {
    // button clicked to go to watch the video
    if (current_display === 'training') {
      // find the most common language
      dispatch(SetCommonLanguageAction());
      // @TODO - this is where we would determine which video to show if there are multiple
      // move to video screen
      dispatch(setBoth({ display: 'video', screen: 'video' }));
    } else {
      if (active_trainee.first_name !== '') {
        // set the language based off of the selected employee
      } else {
        // if no active trainee is set, use the first trainee from the list of current trainees
        dispatch(setActiveTraineeByID(trainees_list[0].id));
      }
      // move to agreement form
      dispatch(setScreen('agreement'));
    }
  };

  return (
    <div>
      {trainees_list[0] !== undefined ? (
        <>
          <div id="training-list-container">
            <Pagination
              data={trainees_list}
              RenderComponent={TraineeListItem}
              pageLimit={0}
              dataLimit={6}
            />
            <div className="trainee-button-wrapper">
              <button className="button button-blue" onClick={startTraining}>
                {current_display === 'training'
                  ? 'Start Training'
                  : 'Agree & Continue'}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}
