import HttpService from './HttpService';

/*
    LANGUAGES
    POST - function to connect to the api via the languages route which takes in language
    data and the addtional language route as its parameter. Inside the function, we
    create an instance of the HttpService and then use the postData function to establish
    a connection with the server. Then return the response.
*/
export const PostCompanyService = (languageData, route) => {
  const http = new HttpService();
  let url = 'companies/' + route; // set to match the route in the backend api for this call
  return http
    .postData(languageData, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

/*
    LANGUAGES
    GET - function to connect to the api via the languages route which takes in language
    data and the addtional language route as its parameter. Inside the function, we
    create an instance of the HttpService and then use the postData function to establish
    a connection with the server. Then return the response.
*/
export const GetCompanyService = (route) => {
  const http = new HttpService();
  let url = 'company/' + route; // set to match the route in the backend api for this call
  return http
    .getData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetCompaniesService = (route) => {
  const http = new HttpService();
  let url = 'companies/' + route; // set to match the route in the backend api for this call
  return http
    .getData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
