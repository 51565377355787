import { createSlice } from '@reduxjs/toolkit';

/**
 * Default state object with initial values.
 */
const initialState = {
    active_language_code: 'en',
    active_language: {
        // temp to cover left panel that shows without logging in
        welcome_text: 'Welcome to',
        welcome_text_2: 'Site-Specific safety training.',
        bottom_text: 'All contractors and visitors at this location must complete the training once per calendar year',
    },
    connectionResponse: '',
    languages_list: [],
};

/**
 * Create a slice as a reducer containing actions.
 */
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
      setActiveLanguage: (state, action) => {
        // Check if action.payload is defined
        if (action.payload) {
          const languageCode = action.payload.toLowerCase(); // Convert to lowercase to ensure consistency
      
          // Find the language in the languages_list with the matching code
          const languageData = state.languages_list.find(lang => lang.code === languageCode);
      
          // Check if the language data exists
          if (languageData) {
            // Update active_language with the found language data
            state.active_language = languageData.data;
            // Update active_language_code with the found language code
            state.active_language_code = languageCode;
          } else {
            console.log(`Language data for code '${languageCode}' not found.`);
          }
        } else {
          console.log('setActiveLanguage: No language code provided');
        }
      },
      
      
    addNewLanguage: (state, action) => {
        let new_lang = action.payload;
      
        if (!state.languages_list.some(lang => lang.code === new_lang.code)) {
          state.languages_list.push({
            code: new_lang.code,
            name: new_lang.title, 
            data: new_lang.data,
          });
        }
        console.log('add new language : state.languages_list', state.languages_list);
      },
      
    setLanguageConnectionResponse: (state, action) => {
        state.connectionResponse = action.payload;
        console.log('state.connectionResponse', state.connectionResponse);
    },

    resetLanguageState: () => initialState, // Reset to initial state
  },
});

export const {
    setActiveLanguage,
    addNewLanguage,
    setLanguageConnectionResponse,
    resetLanguageState,
} = languageSlice.actions;
export default languageSlice.reducer;
