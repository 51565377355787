import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

// import redux-persist utilities for persisting the Redux state
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default: localStorage

// import the specific reducers
import AuthReducer from './reducers/AuthReducer';
import TraineesReducer from './reducers/TraineesReducer';
import LocationReducer from './reducers/LocationReducer';
import CompanyReducer from './reducers/CompanyReducer';
import UserReducer from './reducers/UserReducer';
import PageReducer from './reducers/PageReducer';
import LanguageReducer from './reducers/LanguageReducer';

// configuration for redux-persist: it indicates what key to use in storage and which storage engine to use
const persistConfig = {
  key: 'root',
  storage,
};

// combine multiple reducers into a single root reducer. Each reducer will handle a specific part of the state.
const RootReducer = combineReducers({
  userAuth: AuthReducer,
  page: PageReducer,
  trainees: TraineesReducer,
  language: LanguageReducer,
  location: LocationReducer,
  company: CompanyReducer,
  user: UserReducer,
});

// enhance the root reducer to enable persistence using redux-persist
const persistedReducer = persistReducer(persistConfig, RootReducer);

// use Redux DevTools Extension compose function if available, otherwise fallback to Redux's compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create the Redux store with the persisted reducer, thunk middleware, and potential DevTools support
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// create a persistor object which will handle the process of persisting and rehydrating the store
const persistor = persistStore(store);

export { store, persistor };
