/*
    this file handles all of the actions related to trainees that interact with the backend api
*/
import {
  PostTraineesService,
  PutTraineesService,
  PutTraineeService,
  GetTraineesService,
  GetTraineeService,
  PostTraineeService,
} from '../../services/TraineeServices';
import {
  setTraineeConnectionResponse,
  loadAllTrainees,
  addCompletedTrainee,
  addTrainee,
  setPossibleTrainees,
  setActiveTrainee,
  removeActiveTrainee,
  removeTrainee,
} from '../reducers/TraineesReducer';
import { setScreen } from '../reducers/PageReducer';

export const AddOrUpdateTraineesAction = (traineeData) => {
  let url = 'add-or-update-trainees';
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    PostTraineesService(traineeData, url).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch(setTraineeConnectionResponse(res.message));
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setTraineeConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] AddOrUpdateTraineesAction ()

export const UpdateSingleTraineeAction = (traineeData) => {
  let url = '';
  if (traineeData.id !== null && traineeData.id !== undefined) {
    url = `/update/${traineeData.id}` || ``;
  }
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));

    if (traineeData.id !== null && traineeData.id !== undefined) {
      PostTraineeService(traineeData, url).then(
        (res) => {
          if (res) {
            dispatch(addTrainee(res));
            // clear active trainee
            dispatch(removeActiveTrainee());
            // move back to training lobby
            dispatch(setScreen('training-lobby'));
          } else if (res.hasOwnProperty('success') && res.success === false) {
            dispatch(setTraineeConnectionResponse(res.message));
          }
        },
        (error) => {
          dispatch(setTraineeConnectionResponse(error));
        }
      );
    } else {
      PutTraineesService(traineeData, url).then(
        (res) => {
          if (res) {
            dispatch(addTrainee(res));
            // clear active trainee
            dispatch(removeActiveTrainee());
            // move back to training lobby
            dispatch(setScreen('training-lobby'));
          } else if (res.hasOwnProperty('success') && res.success === false) {
            dispatch(setTraineeConnectionResponse(res.message));
          }
        },
        (error) => {
          dispatch(setTraineeConnectionResponse(error));
        }
      );
    } // [end] return dispatch
  };
}; //[end] AddOrUpdateTraineesAction ()
// this expects just one trainee object to return
export const AddOrUpdateSingleTraineeAction = (traineeData) => {
  let url = '/add-or-update-trainees';
  traineeData = [traineeData]; // api expecting an array of trainees
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    PostTraineesService(traineeData, url).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          // add single trainee to the trainees list state
          dispatch(addTrainee(res));
          // clear active trainee
          dispatch(removeActiveTrainee());
          // move back to training lobby
          dispatch(setScreen('training-lobby'));
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setTraineeConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] AddOrUpdateTraineesAction ()

export const DeleteTraineesAction = (traineeData) => {
  let url = 'delete-trainees';
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    PostTraineesService(traineeData, url).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch(setTraineeConnectionResponse(res.message));
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setTraineeConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] DeleteTraineesAction ()

export const GetTraineeByName = (traineeData) => {
  let url = `/name/${traineeData.first_name}/${traineeData.last_name}/${traineeData.location_id}`;
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    GetTraineeService(url).then(
      (res) => {
        dispatch(loadAllTrainees(res));
        dispatch(setTraineeConnectionResponse(res));
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] DeleteTraineesAction ()

export const GetTraineeByEmail = (traineeData) => {
  let url = `/email/${traineeData.email}/${traineeData.location_id}`;
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    GetTraineeService(url).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch(setTraineeConnectionResponse(res.message));
        } else {
          dispatch(setTraineeConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] DeleteTraineesAction ()

export const GetAllTraineesAction = () => {
  let url = 'get-all-trainees';
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    GetTraineesService(url).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          // add gathered trainees to the app state and update response message
          dispatch(loadAllTrainees(res.data.all_trainees));
          dispatch(setTraineeConnectionResponse(res.message));
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setTraineeConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] GetAllTraineesAction ()

export const GetPossibleTraineesAction = (traineeData, user) => {
  let url = `/phone/${traineeData.phone}/${
    traineeData.location_id || user.location_id
  }`;

  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    GetTraineeService(url).then(
      (res) => {
        if (res === null) {
          url = `/name/${traineeData.first_name}/${traineeData.last_name}/${traineeData.location_id}`;
          GetTraineeService(url).then((res) => {
            if (res !== null && res.length > 0) {
              dispatch(setTraineeConnectionResponse(res));
              // set possible trainees list
              dispatch(setPossibleTrainees(res));
              let trainees_list = [res];

              // now see how many possible trainees were found
              if (
                trainees_list !== '' &&
                trainees_list !== null &&
                trainees_list !== undefined
              ) {
                // if just one employee, do the simple way and add to active trainee state. then show is this you screen
                if (trainees_list !== '') {
                  dispatch(setActiveTrainee(trainees_list[0]));
                  // move to confirmation screen
                  dispatch(setScreen('is-this-you'));
                } else {
                  // many employees match, will have to show a list to choose from
                  // if more than one, show a list to choose which one you are. move to whcihi trainee are you screen
                  dispatch(setScreen('which-trainee-are-you'));
                }
              } else {
                // if zero, send to new trainee form
                // store temp info from form as active trainee
                // first, last, email, company
                let temp_trainee = {};
                if (traineeData.hasOwnProperty('first_name')) {
                  temp_trainee['first_name'] = traineeData.first_name;
                }
                if (traineeData.hasOwnProperty('last_name')) {
                  temp_trainee['last_name'] = traineeData.last_name;
                }
                if (traineeData.hasOwnProperty('phone')) {
                  temp_trainee['phone'] = traineeData.phone;
                }
                if (traineeData.hasOwnProperty('company')) {
                  temp_trainee['company'] = traineeData.company;
                }

                dispatch(setActiveTrainee(temp_trainee));
                //send to new trainee form
                dispatch(setScreen('trainee-information'));
              }
            } else {
              dispatch(setTraineeConnectionResponse(res));
              let temp_trainee = {};
              if (traineeData.hasOwnProperty('first_name')) {
                temp_trainee['first_name'] = traineeData.first_name;
              }
              if (traineeData.hasOwnProperty('last_name')) {
                temp_trainee['last_name'] = traineeData.last_name;
              }
              if (traineeData.hasOwnProperty('phone')) {
                temp_trainee['phone'] = traineeData.phone;
              }
              if (traineeData.hasOwnProperty('company')) {
                temp_trainee['company'] = traineeData.company;
              }

              dispatch(setActiveTrainee(temp_trainee));
              //send to new trainee form
              dispatch(setScreen('trainee-information'));
            }
          });
        } else if (res !== null) {
          dispatch(setTraineeConnectionResponse(res));
          // set possible trainees list
          dispatch(setPossibleTrainees(res));
          let trainees_list = [res];

          // now see how many possible trainees were found
          if (
            trainees_list !== '' &&
            trainees_list !== null &&
            trainees_list !== undefined
          ) {
            // if just one employee, do the simple way and add to active trainee state. then show is this you screen
            if (trainees_list.length === 1) {
              dispatch(setActiveTrainee(trainees_list[0]));
              // move to confirmation screen
              dispatch(setScreen('is-this-you'));
            } else {
              // many employees match, will have to show a list to choose from
              // if more than one, show a list to choose which one you are. move to whcihi trainee are you screen
              dispatch(setScreen('which-trainee-are-you'));
            }
          } else {
            // if zero, send to new trainee form
            // store temp info from form as active trainee
            // first, last, email, company
            let temp_trainee = {};
            if (traineeData.hasOwnProperty('first_name')) {
              temp_trainee['first_name'] = traineeData.first_name;
            }
            if (traineeData.hasOwnProperty('last_name')) {
              temp_trainee['last_name'] = traineeData.last_name;
            }
            if (traineeData.hasOwnProperty('phone')) {
              temp_trainee['phone'] = traineeData.phone;
            }
            if (traineeData.hasOwnProperty('company')) {
              temp_trainee['company'] = traineeData.company;
            }

            dispatch(setActiveTrainee(temp_trainee));
            //send to new trainee form
            dispatch(setScreen('trainee-information'));
          }
        } else {
          dispatch(setTraineeConnectionResponse(res));
          let temp_trainee = {};
          if (traineeData.hasOwnProperty('first_name')) {
            temp_trainee['first_name'] = traineeData.first_name;
          }
          if (traineeData.hasOwnProperty('last_name')) {
            temp_trainee['last_name'] = traineeData.last_name;
          }
          if (traineeData.hasOwnProperty('phone')) {
            temp_trainee['phone'] = traineeData.phone;
          }
          if (traineeData.hasOwnProperty('company')) {
            temp_trainee['company'] = traineeData.company;
          }

          dispatch(setActiveTrainee(temp_trainee));
          //send to new trainee form
          dispatch(setScreen('trainee-information'));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] GetPossibleTraineesAction ()

export const SingleTraineeCompletedTrainingAction = (
  traineeData,
  traineeId
) => {
  let url = '/complete-training';

  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    PutTraineeService(traineeData, url).then(
      (res) => {
        if (res) {
          let trainees_saved = traineeData;
          // add to finished list
          dispatch(addCompletedTrainee(trainees_saved));
          // remove from trainees list
          dispatch(removeTrainee(traineeId));
          // clear active trainee
          dispatch(removeActiveTrainee());
          // show countdown
          dispatch(setScreen('finished'));
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setTraineeConnectionResponse(res));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] GetPossibleTraineesAction ()
