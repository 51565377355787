import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { setScreen } from '../../redux/reducers/PageReducer';
import { setActiveTrainee } from '../../redux/reducers/TraineesReducer';
import { removeActiveTrainee } from '../../redux/reducers/TraineesReducer';
import LoadingButton from '../LoadingButton';

function IsThisYou({ children, className }) {
  const dispatch = useDispatch();
  // Ensure trainee_info is always treated as an array for consistency
  let trainee_info = useSelector((state) => state.trainees.active_trainee);
  if (!Array.isArray(trainee_info)) {
    trainee_info = [trainee_info];
  }

  const language = useSelector(
    (state) => state.language.active_language || state.language
  );

  // This function remains the same
  const newTraineeToForm = () => {
    dispatch(removeActiveTrainee(trainee_info));
    dispatch(setScreen('trainee-information'));
  };

  // Updated to accept a trainee object
  const submitForm = async (event, trainee) => {
    // event.preventDefault();
    dispatch(setActiveTrainee(trainee));
    dispatch(setScreen('trainee-information'));
  };

  const traineeElements = trainee_info.map((element) => (
    <React.Fragment key={element.id}>
      <div className="whos-training-wrapper">
        <h2 className="training-heading">{language.is_this_you_text}</h2>
        <p className="training-text">{language.is_this_you_subtext}</p>
      </div>

      <form onSubmit={(event) => submitForm(event, element)}>
        <div id="is-this-you-info-box">
          <div className="is-this-you-personal-info">
            {element.first_name} {element.last_name}
          </div>
          <div className="is-this-you-personal-info">{element.phone}</div>
          <div className="is-this-you-personal-info">{element.company}</div>
        </div>

        <div className="form-bottom-button-wrapper">
          <LoadingButton
            initialLabel="This Is Me"
            onSubmit={(event) => submitForm(event, element)}
          />
          <LoadingButton initialLabel="Not Me" onSubmit={newTraineeToForm} />
        </div>
      </form>
    </React.Fragment>
  ));

  return <>{traineeElements}</>;
}

export default IsThisYou;
