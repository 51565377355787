import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { SingleTraineeCompletedTrainingAction } from '../../redux/actions/TraineeActions';
import LoadingButton from '../LoadingButton';

function TrainingAgreementSignature() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.active_language);
  const location = useSelector((state) => state.location);
  const user = useSelector((state) => state.user);
  // get current trainee info
  const active_trainee = useSelector((state) => state.trainees.active_trainee);
  const last_name = active_trainee.last_name;
  const first_initial = active_trainee.first_name.substring(0, 1);

  const signaturePad = useRef({}); // get referrence to the sig pad to be able to make calls on it
  const [isEmpty, setIsEmpty] = useState(true); // track if the canvas is empty or not

  // clear the signature canvas
  const clearPad = () => {
    signaturePad.current.clear();
    setIsEmpty(true);
  };

  // check if the signature pad is empty
  const onSign = () => {
    setIsEmpty(signaturePad.current.isEmpty());
  };

  // save the signature and move on to the next screen
  const finishAgreement = () => {
    // Do nothing if the signature pad is empty
    if (isEmpty) {
      return;
    }

    // if the signature fields is not empty -> save image and training data
    var temp_trainee = structuredClone(active_trainee); // get copy of trainee object from state
    var today = moment().format('YYYY-MM-DD hh:mm:ss'); // needs to be in datetime format

    // set trainee data
    temp_trainee.signature = signaturePad.current
      .getTrimmedCanvas()
      .toDataURL('image/png'); // pull signature image as base 64 image and add to trainee for processing
    temp_trainee.last_training = today;
    temp_trainee.last_training_agreement = today;
    temp_trainee.last_training_video = today;
    temp_trainee.company_id = location.company_id;
    temp_trainee.region_id = location.region_id;
    temp_trainee.user_id = user.id;
    temp_trainee.user_first_name = user.first_name;
    temp_trainee.user_last_name = user.last_name;

    // clear pad
    clearPad();
    console.log('temp_trainee', temp_trainee);
    // process trainee completed training
    dispatch(
      SingleTraineeCompletedTrainingAction(temp_trainee, temp_trainee.id)
    );
  }; // [end] signature saved and move on to next screen

  return (
    <>
      <div className="signature-trainee-info">
        <p className="trainee-short-name">
          {first_initial}. {last_name}
        </p>
        <p className="help-text">
          {language.signature_subtext ||
            'Please use your finger to sign in the designated area below'}
        </p>
      </div>

      <div id="signature-pad" className="signature-pad-wrapper">
        <div className="signature-pad-body">
          <SignatureCanvas
            penColor="#1E2B3C"
            canvasProps={{ className: 'sigCanvas' }}
            ref={signaturePad}
            onEnd={onSign}
          />
        </div>
      </div>

      <div className="review-card-footer">
        <button
          className="button button-light"
          id="signature-pad-clear-button"
          onClick={clearPad}
        >
          {language.clear_button || 'Clear'}
        </button>
        <LoadingButton
          initialLabel={language.continue_button || 'Continue'}
          onSubmit={finishAgreement}
          type="submit"
          loadingLabel="Saving..."
          extraClasses={isEmpty ? 'inactive' : ''}
        />
      </div>
    </>
  ); // [end] return function
} // end argeement signature function

export default TrainingAgreementSignature;
