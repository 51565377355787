
const CardCentered = ({children, className }) => (
    <div className={`card-centered ${className}`}>
      <div className="container">
        {children}
      </div>
    </div>
)

export default CardCentered
