import React from 'react';
import { PostDataService } from '../../services/DataServices';
import { useForm } from 'react-hook-form';
import { setScreen } from '../../redux/reducers/PageReducer';
import { removeActiveTrainee } from '../../redux/reducers/TraineesReducer';
import { UpdateSingleTraineeAction } from '../../redux/actions/TraineeActions';
import { useSelector, useDispatch } from 'react-redux';

export default function TraineeInfoForm() {
  const language = useSelector(
    (state) => state.language.active_language || state.language
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const dispatch = useDispatch();
  const active_trainee = useSelector((state) => state.trainees.active_trainee);
  const location = useSelector((state) => state.location);
  const company = useSelector((state) => state.company);
  const onSubmit = (data) => {
    // create a clone of the trainee from state

    // Create a copy of active_trainee object
    const activeTraineeCopy = { ...active_trainee };

    activeTraineeCopy.location_id =
      activeTraineeCopy.location_id || location.id;
    activeTraineeCopy.company_id = activeTraineeCopy.company_id || company.id;
    activeTraineeCopy.region_id =
      activeTraineeCopy.region_id || location.region_id;
    activeTraineeCopy.checked_in = activeTraineeCopy.checked_in || false;
    activeTraineeCopy.email = activeTraineeCopy.email || 'blank@q4impact.com';
    activeTraineeCopy.company = activeTraineeCopy.company || 'none';

    // replace the trainee data from the form in case it was updated
    for (const [key, value] of Object.entries(data)) {
      activeTraineeCopy[key] = value;
    }

    dispatch(UpdateSingleTraineeAction(activeTraineeCopy));
  }; //[end] submit function

  const goBack = (e) => {
    e.preventDefault(); // Prevent form submission
    // clear active trainee
    dispatch(removeActiveTrainee());
    // move back to training lobby
    dispatch(setScreen('training-lobby'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div
          className={`half-width ${errors['first_name'] ? 'has-error' : ''}`}
        >
          <label htmlFor="first_name">{language.form_first_name} *</label>
          <input
            type="text"
            name="first_name"
            placeholder={`${language.form_first_name_placeholder}`}
            {...register('first_name', {
              // required: true,
              // minLength: 3,
              // maxLength: 65,
              required: 'First name is required',
              minLength: {
                value: 3,
                message: 'First name must be at least 3 characters',
              },
              maxLength: {
                value: 65,
                message: 'First name must be no more than 65 characters',
              },
            })}
            defaultValue={active_trainee.first_name}
          />
          {errors.first_name && (
            <p className="error-message">{errors.first_name.message}</p>
          )}
        </div>
        <div className={`half-width ${errors['last_name'] ? 'has-error' : ''}`}>
          <label htmlFor="last_name">{language.form_last_name} *</label>
          <input
            type="text"
            name="last_name"
            placeholder={`${language.form_last_name_placeholder}`}
            {...register('last_name', {
              required: 'Last name is required',
              minLength: {
                value: 3,
                message: 'Last name must be at least 3 characters',
              },
              maxLength: {
                value: 65,
                message: 'Last name must be no more than 65 characters',
              },
            })}
            defaultValue={active_trainee.last_name}
          />
          {errors.last_name && (
            <p className="error-message">{errors.last_name.message}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className={`half-width ${errors['email'] ? 'has-error' : ''}`}>
          <label htmlFor="email">{language.form_email}</label>
          <input
            type="email"
            name="email"
            placeholder={`${language.form_email_placeholder}`}
            {...register('email', {
              required: false,
              minLength: {
                value: 9,
                message: 'Email must be at least 6 characters',
              },
              maxLength: {
                value: 65,
                message: 'Email must be no more than 65 characters',
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)*$/,
                message: 'Invalid email address',
              },
            })}
            defaultValue={active_trainee.email}
          />
          {errors.email && (
            <p className="error-message">{errors.email.message}</p>
          )}
        </div>

        <div className={`half-width ${errors['phone'] ? 'has-error' : ''}`}>
          <label htmlFor="phone">{language.form_phone} *</label>
          <input
            type="phone"
            name="phone"
            placeholder={`${language.form_phone_placeholder}`}
            {...register('phone', {
              required: 'Phone number is required',
              minLength: {
                value: 10,
                message: 'Phone must be at least 10 characters',
              },
              maxLength: {
                value: 18,
                message: 'Phone must be no more than 18 characters',
              },
            })}
            defaultValue={active_trainee.phone}
          />
          {errors.phone && (
            <p className="error-message">{errors.phone.message}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className={`half-width ${errors['company'] ? 'has-error' : ''}`}>
          <label htmlFor="company">{language.form_company} *</label>
          <input
            type="text"
            name="company"
            placeholder={`${language.form_company_placeholder}`}
            {...register('company', {
              required: 'Company name is required',
              minLength: {
                value: 3,
                message: 'Company name must be at least 3 characters',
              },
              maxLength: {
                value: 65,
                message: 'Company name must be no more than 65 characters',
              },
            })}
            defaultValue={active_trainee.company}
          />
          {errors.company && (
            <p className="error-message">{errors.company.message}</p>
          )}
        </div>
        <div
          className={`half-width ${errors['visitor_type'] ? 'has-error' : ''}`}
        >
          <label htmlFor="visitor_type">{language.form_visitor_type} *</label>
          <select
            name="visitor_type"
            {...register('visitor_type', {
              required: 'Visitor Type is required',
            })}
            defaultValue={active_trainee.visitor_type}
          >
            <option value="">{language.form_visitor_type_please_select}</option>
            <option value="contractor">
              {language.form_visitor_type_contractor}
            </option>
            <option value="vendor">{language.form_visitor_type_vendor}</option>
            <option value="guest">{language.form_visitor_type_guest}</option>
          </select>
          {errors.visitor_type && (
            <p className="error-message">{errors.visitor_type.message}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div
          className={`half-width ${errors['msha_number'] ? 'has-error' : ''}`}
        >
          <label htmlFor="msha_number">{language.form_msha_number}</label>
          <input
            type="text"
            name="msha_number"
            placeholder={`${language.form_msha_number_placeholder}`}
            {...register('msha_number', {
              required: false,
              minLength: {
                value: 3,
                message: 'MSHA number must be at least 3 characters',
              },
              maxLength: {
                value: 65,
                message: 'MSHA number must be no more than 65 characters',
              },
            })}
            defaultValue={active_trainee.msha_number}
          />
          {errors.msha_number && (
            <p className="error-message">{errors.msha_number.message}</p>
          )}
        </div>
        <div
          className={`half-width ${errors['truck_number'] ? 'has-error' : ''}`}
        >
          <label htmlFor="truck_number">{language.form_truck_number}</label>
          <input
            type="text"
            name="truck_number"
            placeholder={`${language.form_truck_number_placeholder}`}
            {...register('truck_number', {
              required: false,
              minLength: {
                value: 3,
                message: 'Truck number must be at least 3 characters',
              },
              maxLength: {
                value: 65,
                message: 'Truck number must be no more than 65 characters',
              },
            })}
            defaultValue={active_trainee.truck_number}
          />
          {errors.truck_number && (
            <p className="error-message">{errors.truck_number.message}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div
          className={`half-width ${
            errors['preferred_language'] ? 'has-error' : ''
          }`}
        >
          <label htmlFor="preferred_language">
            {language.form_preferred_lang}
          </label>
          <select
            name="preferred_language"
            {...register('preferred_language', { required: false })}
            defaultValue={active_trainee.preferred_language}
          >
            <option value="">
              {language.form_preferred_lang_please_select}
            </option>
            <option value="en">{language.form_preferred_lang_en}</option>
            <option value="es">{language.form_preferred_lang_es}</option>
          </select>
          {errors.preferred_language && (
            <p className="error-message">{errors.preferred_language.message}</p>
          )}
        </div>
      </div>

      <div className="form-bottom-button-wrapper">
        <button className="button button-blue" onClick={goBack}>
          {language.go_back_button}
        </button>
        <button
          type="submit"
          className="button button-blue"
          //onClick={handleSubmit(onSubmit)}
        >
          {language.continue_button}
        </button>
      </div>
    </form>
  ); // [end] return
} // [end] TraineeInfoForm function
