//import Image from 'next/image'
import image from '../assets/images/Tervuren-Gray-Logo-Small.png'

const CompanyLogo = props => (
  <>
    <img src={image} alt="Company Logo"{...props}/>
  </>
)

export default CompanyLogo
