import Dropdown from './Dropdown';
import { DropdownButton } from './DropdownLink';
import image from '../assets/images/language-icon.png';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveLanguage } from '../redux/reducers/LanguageReducer';

const LanguageSelector = ({ props }) => {
  const language_options = useSelector((state) => state.language.languages_list);
  const activeLanguageCode = useSelector((state) => state.language.active_language_code);
  const dispatch = useDispatch();

  const setLanguageAction = (lang) => {
    dispatch(setActiveLanguage(lang));
  };
  const languageChoices = language_options.map((lang) => (
    <DropdownButton key={lang.code} onClick={() => setLanguageAction(lang.code)}>
      {lang.name}
    </DropdownButton>
  ));
  return (
    <>
      <Dropdown
        align="right"
        width="48"
        trigger={ 
            <button className="language-selector-btn">
                <div {...props}>
                    {/* Display the active language code in uppercase */}
                    {activeLanguageCode ? activeLanguageCode.toUpperCase() : 'EN'}
                    <img src={image} alt={`Lang ${activeLanguageCode?.toUpperCase() || 'EN'}`} className="language-image"/>
                </div>
            </button>
        }
        >
      
        {languageChoices}
      </Dropdown>
    </>
  );
};
export default LanguageSelector;
