import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

// import general layout components
import LeftPanel from '../Layouts/LeftPanel';
import RightPanel from '../Layouts/RightPanel';
import CompanyLogo from '../CompanyLogo';
import LanguageSelector from '../LanguageSelector';
import CardCentered from '../CardCentered';
import ForgotPasswordForm from '../Auth/ForgotPasswordForm';



function ForgotPassword() {

    const authResponseSuccess = useSelector(state=>state.userAuth.authResponse.success);

    return (
        <>
        <div className="bg-topo-dark">
                <LeftPanel/>
                <RightPanel className="bg-white">
                    <CompanyLogo className="white-panel-company-logo" />
                    <LanguageSelector className="language-selector text-navy" />
                    <CardCentered className="auth-card">

                        { authResponseSuccess ? (
                            <div className="row auth-response-text">
                                <h3>We have e-mailed your password reset link!</h3>
                                <div className="row">
                                    <Link to="/login">Return to login</Link>
                                </div>
                            </div>
                        ) :
                            <ForgotPasswordForm />
                        }

                    </CardCentered>
                </RightPanel>
            </div>
        </>
    ) // [end] return function



} //[end] ForgotPassword
export default ForgotPassword
