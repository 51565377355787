// import general layout components
import CardCentered from '../CardCentered';
import LanguageSelector from '../LanguageSelector';

// start imports of all of the options in the training review
import TrainingStart from '../TrainingReview/TrainingStart';
import TrainingAgreement from '../TrainingReview/TrainingAgreement';
import TrainingAgreementSignature from '../TrainingReview/TrainingAgreementSignature';
import TrainingFinished from '../TrainingReview/TrainingFinished';
import TrainingLobby from '../TrainingSignup/TrainingLobby';
import { setBoth } from '../../redux/reducers/PageReducer';
import { useSelector, useDispatch } from 'react-redux';
// choose which component to display
//const display = 'start'; // this will be set based on state

function renderCorrectComponent(current_page, dispatch) {
  switch (current_page) {
    case 'start':
      return <TrainingStart />;
    case 'agreement':
      return <TrainingAgreement />;
    case 'signature':
      return <TrainingAgreementSignature />;
    case 'finished':
      return <TrainingFinished />;
    default:
      dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
      return null; // Need to return a value in the default case
  }
} // [end] renderCorrectComponent()

function ReviewDisplay() {
  const current_page = useSelector((state) => state.page.screen);
  const dispatch = useDispatch(); // Get dispatch function from React-Redux
  return (
    <>
      <div className="bg-topo-light fullscreen">
        <CardCentered className="review-card">
          <LanguageSelector className="language-selector text-navy" />
          {renderCorrectComponent(current_page, dispatch)}
        </CardCentered>
      </div>
    </>
  );
}

export default ReviewDisplay;
