
import React from 'react';
import { useSelector } from 'react-redux';




export default function CheckInIFrame() {
    const location_id = useSelector((state) => state.user.location_id);
    const region_id = useSelector((state) => state.user.region_id);
    const company_id = useSelector((state) => state.user.company_id);
    return (
        <div style={{ height: '75vh', width: '25vw', textAlign: 'center' }}>

            <iframe
                src={`https://checkin.tervuren.app/checkin/${company_id}/${region_id}/${location_id}/`}
                title="Check In/Out"
                width="100%"
                height="100%"
            ></iframe>
        </div>
    )
    }