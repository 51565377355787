import { GetLanguagesService } from '../../services/LanguageServices';
import {
  setActiveLanguage,
  addNewLanguage,
  setLanguageConnectionResponse,
} from '../reducers/LanguageReducer';

/*
 * This function imports all of the languages from the api and loops through them to add them to the frontend.
 * once they are added, set the active language to the compnaies preferred language
 */
export const getAllLanguagesAction = () => {
  let url = '';
  return (dispatch, getState) => {
    const state = getState();
    // get location's preferred language
    const location_lang = state.location.preferred_language;
    //clear response state
    dispatch(setLanguageConnectionResponse(''));
    GetLanguagesService(url).then(
      (res) => {
        if (res) {
          // add gathered languages to the app state
            for (var lang in res) {
                dispatch(addNewLanguage(res[lang]));
            }

          // set the active language to the location's default
          if(location_lang !== ''){
            dispatch(setActiveLanguage(location_lang));
            }else{
                dispatch(setActiveLanguage('en'));
            }
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch(setLanguageConnectionResponse(res.message));
        }
      },
      (error) => {
        dispatch(setLanguageConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] AddOrUpdateTraineesAction ()

/*
 * This function loops through all of the current trainees in the program and decides which language is the most common between all of the trainees.
 * Then will set that as the main langauges to use for training
 */
export function SetCommonLanguageAction() {
  return (dispatch, getState) => {
    var trainee_languages_array = [];
    var lang_frequency = {};
    var max_frequency = 0;
    var most_common_language;
    const state = getState();

    // get all active trainees
    const trainees_list = state.trainees.trainee_list;

    // loop through each and add language to array
    trainees_list.forEach(function(trainee){
        if(trainee.preferred_language !== '' && trainee.preferred_language !== null){
            trainee_languages_array.push(trainee.preferred_language)
        }
    });

    // count all the languages and choose most common
    // @TODO: if there are two languages with equal amounts of trainees, it will choose the first of the two added to the list. maybe change this to go to the preferred language of the location
    for (var lang in trainee_languages_array) {
      lang_frequency[trainee_languages_array[lang]] =
        (lang_frequency[trainee_languages_array[lang]] || 0) + 1; // increment frequency.
      if (lang_frequency[trainee_languages_array[lang]] > max_frequency) {
        // is this frequency > max so far ?
        max_frequency = lang_frequency[trainee_languages_array[lang]]; // update max.
        most_common_language = trainee_languages_array[lang]; // update result.
      }
    }

    // set to the active language
    dispatch(setActiveLanguage(most_common_language));
  };
} // [end] SetCommonLanguageAction()
