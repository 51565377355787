import * as ActionTypes from '../ActionTypes';
import {
  RegisterUserService,
  LoginUserService,
  ForgotPasswordService,
  ResetPasswordService,
  TestUserService,
} from '../../services/AuthServices';
import { setAllUserInfo } from '../reducers/UserReducer';
import { setAllLocationInfo } from '../reducers/LocationReducer';
import { setAllCompanyInfo } from '../reducers/CompanyReducer';
import { GetCompanyService } from '../../services/CompanyServices';
import { GetLocationService } from '../../services/LocationsServices';
import { GetLanguagesService } from '../../services/LanguageServices';
import { setActiveLanguage } from '../reducers/LanguageReducer';
import { setScreen } from '../../redux/reducers/PageReducer';
import { persistor, store } from '../../redux/Store';
import { resetCompanyState } from '../reducers/CompanyReducer';
import { resetLanguageState } from '../reducers/LanguageReducer';
import { resetLocationState } from '../reducers/LocationReducer';
import { resetPageState } from '../reducers/PageReducer';
import { resetTraineesState } from '../reducers/TraineesReducer';
import { resetUserState } from '../reducers/UserReducer';
import { getAllLanguagesAction } from './LanguageActions';

export const RegisterAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    RegisterUserService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.SIGNUP_SUCCESS, res });
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.SIGNUP_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const LoginAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });
    dispatch(setScreen('training-lobby'));
    LoginUserService(credentials).then(
      (res) => {
        history('/');
        if (res.hasOwnProperty('success') && res.success === true) {
          // set user token
          localStorage.setItem('user-token', res.token);
          // set login success
          dispatch({ type: ActionTypes.LOGIN_SUCCESS });

          // get languages
          dispatch(getAllLanguagesAction());
          // set user info
          dispatch(setAllUserInfo(res.user));

          GetCompanyService(`id/${res.user.company_id}`).then(
            (res) => {
              if (res) {
                dispatch(setAllCompanyInfo(res));
              } else {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
              }
            },
            (error) => {
              dispatch({ type: ActionTypes.CODE_ERROR, error });
            }
          );

          GetLocationService(`id/${res.user.location_id}`).then(
            (res) => {
              if (res) {
                console.log('location res', res);
                dispatch(setAllLocationInfo(res));
              } else {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
              }
            },
            (error) => {
              dispatch({ type: ActionTypes.CODE_ERROR, error });
            }
          );

          GetLanguagesService().then(
            (res) => {
              if (res) {
                dispatch(setActiveLanguage(res.data));
              } else if (!res) {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
              }
            },
            (error) => {
              dispatch({ type: ActionTypes.CODE_ERROR, error });
            }
          );
          dispatch(setScreen('training-lobby'));
          // move authenticated to main training screen
          setTimeout(() => {
            history('/');
          }, 1);
        } else {
          dispatch({ type: ActionTypes.LOGIN_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const LogoutAction = (history) => {
  return (dispatch) => {
    // Redirect to login screen
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE }); // Reset authentication state

    // Redirect to login screen

    // Reset screens if necessary
    // dispatch(setScreen('training-lobby'));
    // dispatch(setScreen('which-trainee-are-you'));

    // Dispatch logout action
    store.dispatch({ type: 'LOGOUT' });
    // clear the states
    dispatch(resetCompanyState());
    dispatch(resetLanguageState());
    dispatch(resetLocationState());
    dispatch(resetPageState());
    dispatch(resetTraineesState());
    dispatch(resetUserState());

    // Purge the persisted state
    persistor.purge();

    // Clear user token from localStorage
    localStorage.removeItem('user-token');
  };
};

export const ClearAuthStateAction = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
  };
};

export const ForgotPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ForgotPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, res });
          //setTimeout(() => { history('/'); },1500);
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const ResetPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ResetPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_SUCCESS, res });
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const TestUserAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    TestUserService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
