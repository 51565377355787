import { useState } from 'react';

import nextArrow from '../assets/images/next-arrow.png';


function Pagination({ data, RenderComponent, title, pageLimit, dataLimit, selectList, selectedItemId }) {

    var [pages] = useState(Math.ceil(data.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1);

    function goToNextPage() {
        if(currentPage < pages){
            setCurrentPage((page) => page + 1);
        }
    }

    function goToPreviousPage() {
        if(currentPage > 1){
            setCurrentPage((page) => page - 1);
        }
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return data.slice(startIndex, endIndex);
    };


    return (

        <div>
            {/* show the posts, xx posts at a time */}
            <div id="trainee-list-wrapper">
                {getPaginatedData().map((d, idx) => (
                    <RenderComponent key={idx} data={d} />
                ))}
            </div>

            {/* show the pagiantion */}
            <div className="pagination" id="trainee-list-button-wrapper">
                {/* previous button */}
                <button onClick={goToPreviousPage} className={`pagination-buttons ${currentPage === 1 ? 'disabled' : ''}`} >
                    <img className="previous" src={nextArrow} alt="Previous Arrow"/>
                </button>

                {/* show page numbers */}
                <p>{currentPage} of {pages}</p>

                {/* next button */}
                <button onClick={goToNextPage} className={`pagination-buttons ${currentPage === pages ? 'disabled' : ''}`} >
                    <img src={nextArrow} alt="Next Arrow"/>
                </button>
            </div>
        </div>

    ); //[end] return function
}

export default Pagination
// the base of this code was from here: from here: https://academind.com/tutorials/reactjs-pagination
