import React from 'react';
import { LogoutAction } from '../../redux/actions/AuthActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(LogoutAction());
    nav('/login');
  });
}
