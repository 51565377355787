import WhosTraining from './WhosTraining';
import TraineeList from '../TraineeList';
import { useDispatch, useSelector} from 'react-redux';
import { clearPossibleTrainees } from '../../redux/reducers/TraineesReducer';
import { useEffect } from 'react';

function TrainingLobby (){

    const dispatch = useDispatch();
    // clear possible trainees list | trigger on component mount
    useEffect(() => {
        dispatch(clearPossibleTrainees());
        // next comment line -> old es lint versions flag this but it is okay. this is just hiding a warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const number_of_trainees = useSelector((state) => state.trainees.number_of_trainees);

    // show the trainee list if trainees exist
    return(
        <>
            <div className="">
                <WhosTraining/>
                {number_of_trainees > 0 ? <TraineeList/> : null}
            </div>
        </>
    )
}

export default TrainingLobby
