import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {store, persistor} from './redux/Store';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate

/*
    React.StrictMode s a tool for highlighting potential problems in an application.
    Like Fragment , StrictMode does not render any visible UI. It activates additional checks and warnings
    for its descendants. Note: Strict mode checks are run in development mode only;
    they do not impact the production build.

    to use strict mode, wrap the provider compomonent with:
        <React.StrictMode> </React.StrictMode>
*/
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
