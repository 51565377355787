import HttpService from './HttpService';

/*
    TRAINEES
    POST - function to connect to the api via the trainees route which takes in trainee
    data and the addtional trainee route as its parameter. Inside the function, we
    create an instance of the HttpService and then use the postData function to establish
    a connection with the server. Then return the response.
*/
export const PostTraineesService = (traineeData, route) => {
  const http = new HttpService();
  let url = 'trainees' + route; // set to match the route in the backend api for this call
  return http
    .postData(traineeData, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const PutTraineesService = (traineeData, route) => {
  const http = new HttpService();
  let url = 'trainees' + route; // set to match the route in the backend api for this call
  return http
    .putData(traineeData, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const PostTraineeService = (traineeData, route) => {
  const http = new HttpService();
  let url = 'trainee' + route; // set to match the route in the backend api for this call
  return http
    .postData(traineeData, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
export const PutTraineeService = (traineeData, route) => {
  const http = new HttpService();
  let url = 'trainee' + route; // set to match the route in the backend api for this call
  return http
    .putData(traineeData, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

/*
    TRAINEES
    GET - function to connect to the api via the trainees route which takes in trainee
    data and the addtional trainee route as its parameter. Inside the function, we
    create an instance of the HttpService and then use the postData function to establish
    a connection with the server. Then return the response.
*/
export const GetTraineesService = (route) => {
  const http = new HttpService();
  let url = 'trainees' + route; // set to match the route in the backend api for this call
  return http
    .getData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const GetTraineeService = (route) => {
  const http = new HttpService();
  let url = 'trainee' + route; // set to match the route in the backend api for this call
  return http
    .getData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
